<template>
    <div>
      <div v-if="$route.name === 'My property'">
        <div class="header">
          <v-btn class="btn_plus"
                 color="primary"
                 outlined
                 dense
                 small
                 @click="goToCreateObject"
          >
            {{$t('objects["Новый объект"]')}}
          </v-btn>
        </div>
        <h2 class="objects-empty">It is empty here for now, add your first property</h2>
      </div>
      <div v-else>
        <div class="header" style="width:100%;
                                  margin: 280px auto;
                                  display:flex;
                                  align-items:center;
                                  justify-content:center;
                                  text-align: center;
                                  font-weight: 400;
                                  font-size: 14px;
                                  line-height: 1.33;
                                  color: #a3a4a5;">
          <h2>You don`t have any properties, it is time to <router-link :to="{name: 'New property', params: {lang: this.$route.params.lang}}">create your first property</router-link></h2>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    },
    data: () => ({

    }),
    methods: {
        async goToCreateObject() {
            await this.$router.push({name: 'New property', params: {lang: this.$route.params.lang}})
        },
    }
};
</script>

<style lang="scss">
.objects-empty {
    position: relative;
    display: flex;
    max-width: 350px;
    margin: 120px auto;
    padding-top: 150px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    color: #a3a4a5;
    background: url(~@/assets/img/hotel.svg) center top/120px no-repeat;
    &::after {
        content: '';
        position: absolute;
        top: -110%;
        left: 120%;
        width: 198px;
        height: 364px;
        background: url(~@/assets/img/arrow.svg) center/contain no-repeat;
    }
}

.left {
    display: flex;
    flex-wrap: wrap;
}

.tovar {
    width: 25%;
}
</style>
