<template>
  <main>
    <template v-if="objects.list.length">
      <div style="display:flex;margin-bottom: 15px;justify-content: space-between">
        <h1 class="heading" v-if="!isMobile">My property</h1>
        <div v-if="isMobile"></div>
        <v-btn
          class="btn_plus"
          color="primary"
          small
          outlined
          :style="`max-width:${isMobile?'150px':'255px'}`"
          dense
          :to="{ name: 'New property', params: { lang: $route.params.lang } }"
        >
          {{ $t('objects["Новый объект"]') }}
        </v-btn>
      </div>
      <div class="objects">
        <div
          class="object"
          v-for="(object, objectIndex) in objects.list"
          :key="object.id"
          :style="`border-color:${
            $route.params.new && objectIndex === 0 ? '#ffd900' : '#e4e4e4'
          };`"
        >
          <span class="object__pwa" v-if="object.pwa">pwa</span>
          <span
            class="object__status"
            style="cursor: pointer; background-color: #ff9800"
            v-if="object.tariff_id === 1&&$store.state.profile.id === object.owner_id"
            @click="$refs.PopupTariff.open(object)"
            >free</span
          >
          <span
            class="object__status"
            style="cursor: pointer; background-color: #009688"
            v-if="object.tariff_id === 2&&$store.state.profile.id === object.owner_id"
            @click="$refs.PopupTariff.open(object)"
            >standart</span
          >
          <span
            class="object__status"
            style="cursor: pointer; background-color: #b5004b"
            v-if="object.tariff_id === 3&&$store.state.profile.id === object.owner_id"
            @click="$refs.PopupTariff.open(object)"
            >pro</span
          >
          <span
              class="object__status"
              style="cursor: pointer; background-color: #ff9800"
              v-if="object.tariff_id === 1&&$store.state.profile.id !== object.owner_id"
          >free</span
          >
          <span
              class="object__status"
              style="cursor: pointer; background-color: #009688"
              v-if="object.tariff_id === 2&&$store.state.profile.id !== object.owner_id"
          >standart</span
          >
          <span
              class="object__status"
              style="cursor: pointer; background-color: #b5004b"
              v-if="object.tariff_id === 3&&$store.state.profile.id !== object.owner_id"
          >pro</span
          >
          <span
            class="object__status object__status_expired"
            v-if="object.status === 4"
            >не активен</span
          >
          <div class="object__header" style="display: flex">
            <div
              class="object__label"
              style="
                border: 1px solid #ccc;
                height: 58px;
                line-height: 60px;
                vertical-align: middle;
                width: 60px;
                text-align: center;
                font-size: 30px;
                margin: 0 15px 0 0;
              "
            >
              {{ logoTest(object.name) }}
            </div>
            <div>
              <h2 class="object__name" style="display: block;">
                {{ object.name }}
                <v-icon
                  color="primary"
                  small
                  style="margin-bottom: 3px;"
                  @click="changeName(object.name, objectIndex)"
                  >mdi-pencil</v-icon
                >
              </h2>
              <span class="object__label" style="margin-bottom: 0"
                >Project ID: {{ object.id }}</span
              >
<!--              <span class="object__label" style="margin-bottom: 0"-->
<!--              >Role: {{ $store.state.profile.id === object.owner_id?'owner':'manager' }}</span-->
<!--              >-->
              <span class="object__label" style="margin-bottom: 30px"
                >Created {{ dateObj(object.created_at) }}</span
              >
              <div class="object__actions" v-if="!$store.state.isMobile">
                <v-btn
                  class="object__action"
                  style="background: rgb(250, 250, 250); border-radius: 5px"
                  icon
                  small
                  v-if="!$store.state.profile.is_manager"
                  @click="cloneObject(object.id)"
                >
                  <v-icon size="24px" color="primary">mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  class="object__action"
                  style="background: rgb(250, 250, 250); border-radius: 5px"
                  icon
                  small
                  v-if="!$store.state.profile.is_manager"
                  @click="deleteObject(object.id)"
                >
                  <v-icon size="24px" color="#7f8385"
                    >mdi-delete-outline</v-icon
                  >
                </v-btn>
              </div>
            </div>
          </div>
          <div class="object__content">
            <div class="object__info__high">
              <span class="object__label">{{ $t('objects["Адрес"]') }}</span>
              <div class="object__value" style="display: block;">
                {{ object.address }}
                <v-icon
                  color="primary"
                  @click="changeAddress(object.address, objectIndex)"
                  style="margin-bottom: 3px;"
                  small
                  >mdi-pencil</v-icon
                >
              </div>
            </div>
            <div class="object__info__medium" :style="`width:${isMobile?'calc(58% - 30px)':'calc(33.3% - 30px)'};`">
              <span class="object__label">Mobile site</span>
              <div class="object__value">
                <a :href="`https://guest.eco/${object.id}`" target="_blank">Go to web site</a>
              </div>
            </div>

            <div class="object__info" v-if="!isMobile">
              <span class="object__label" style="margin-bottom: 0;">Open mobile app</span>
              <div class="object__value">{{ object.visitors }}</div>
            </div>
            <div class="object__info__high" style="width:50%;" v-if="isMobile">
              <span class="object__label" style="margin-bottom: 0;">Open mobile app</span>
              <div class="object__value">{{ object.visitors }}</div>
            </div>
            <div class="object__info__medium" v-if="isMobile">
              <span class="object__label" style="margin-bottom: 0;">Reviews</span>
              <div class="object__value">
                {{ object.reviews }}
              </div>
            </div>
            <div class="object__info__high">
              <div class="object__value">
                <div class="object__subinfo">
                  <span class="object__label">{{
                    $t('objects["Лендинг"]')
                  }}</span>
                  <v-btn
                    class="object__sticker-download"
                    color="greylight"
                    depressed
                    small
                    @click="
                      openRoute('Constructor', object.id, $route.params.lang)
                    "
                  >
                    <v-icon color="primary">mdi-hand-front-left</v-icon>
                    Customize by constructor
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="object__info__medium" :style="`width:${isMobile?'calc(58% - 30px)':'calc(33.3% - 30px)'};`">
            </div>
            <div class="object__info" v-if="!isMobile">
              <span class="object__label" style="margin-bottom: 0;">Reviews</span>
              <div class="object__value">
                {{ object.reviews }}
              </div>
            </div>
            <div class="object__info__high">
              <span class="object__label">Features</span>
              <div class="object__value">
                <v-btn
                  class="object__sticker-download"
                  color="greylight"
                  depressed
                  small
                  style="margin-bottom: 15px"
                  @click="openRoute('Reviews', object.id, $route.params.lang)"
                >
                  <v-icon color="primary">mdi-application-export</v-icon>
                  Reviews
                </v-btn>
              </div>
            </div>
            <div class="object__info__medium" :style="`width:${$store.state.isMobile?'calc(58% - 30px)':'calc(33.3% - 30px)'};`">
              <span class="object__label">QR code</span>
              <div class="object__value">
                <v-btn
                  class="object__sticker-order"
                  color="greylight"
                  depressed
                  small
                  @click="openQr(object)"
                >
                  <v-icon color="primary">mdi-file-outline</v-icon>
                  Open QR
                </v-btn>
              </div>
            </div>
            <div class="object__info__high" v-if="$store.state.isMobile">
              <div class="object__value">
                <v-btn
                    class="object__sticker-download"
                    color="greylight"
                    depressed
                    small
                    style="margin-bottom: 15px"
                    @click="cloneObject(object.id)"
                    v-if="!$store.state.profile.is_manager"
                >
                  <v-icon color="primary">mdi-content-copy</v-icon>
                  Copy
                </v-btn>
              </div>
            </div>
            <div class="object__info__medium" v-if="$store.state.isMobile" :style="`width:${$store.state.isMobile?'calc(58% - 30px)':'calc(33.3% - 30px)'};`">
              <div class="object__value">
                <v-btn
                    class="object__sticker-order"
                    color="greylight"
                    depressed
                    v-if="!$store.state.profile.is_manager"
                    small
                    @click="deleteObject(object.id)"
                >
                  <v-icon color="primary">mdi-delete</v-icon>
                  Delete
                </v-btn>
              </div>
            </div>
            </div>
        </div>
      </div>
      <PopupDelete
        ref="PopupDelete"
        :title="$t('objects.delete_confirm', { name: object.name })"
        @confirm="confirm"
      />
    </template>
    <ObjectsEmpty :title="$t('objects[\'Мои объекты\']')" v-else />
    <PopupEmail
      ref="PopupEmail"
      :title="$t('popup.sticker_email', { email: 'me@bs.hr' })"
    />
    <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogQr" width="400">
      <v-card>
          <v-card-title style="width:100%;justify-content:center;margin-bottom: 15px;">
              Your qr code
          </v-card-title>
      <v-card-text>
          <v-icon
                  style="position: absolute; top: 10px; right: 10px"
                  @click="dialogQr = false"
          >
              mdi-close
          </v-icon>
          <qr-code
                  id="qr_code"
                  :text="qrLink"
                  :size="256"
                  error-level="H"
                  style="display: flex; justify-content: center; align-items: center"
          ></qr-code>
      </v-card-text>
        <v-card-actions style="display:flex;justify-content: center;align-items: center;padding:15px;">
          <v-btn
            class="object__sticker-download"
            color="primary"
            depressed
            outlined
            small
            @click="downloadQr()">
            <v-icon color="primary">mdi-download</v-icon>
            Download QR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogName" width="400">
      <v-card style="height: 215px">
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="dialogName = false"
          >mdi-close</v-icon
        >
        <v-card-title>Change Name of {{ changedName }}</v-card-title>
        <div style="padding: 0 16px">
          <v-text-field v-model="name" :rules="nameRules" outlined dense></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            style="color: #fff"
            @click="saveName"
            small
            outlined
            :disabled="!name"
            dense
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogAddress" width="400">
      <v-card style="height: 215px">
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="dialogAddress = false"
          >mdi-close</v-icon
        >
        <v-card-title>Change Address of {{ changedName }}</v-card-title>
        <div style="padding: 0 16px">
          <v-text-field v-model="address" :rules="nameRules" outlined dense></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            outlined
            :disabled="!address"
            dense
            style="color: #fff"
            @click="saveAddress"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupTariff ref="PopupTariff" />
    <v-dialog :fullscreen="$store.state.isMobile" v-model="deleteModal" max-width="500px">
      <v-card>
        <v-card-title> Delete property </v-card-title>
        <v-card-text>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDeleteModal"
          >
            mdi-close
          </v-icon>
          Destroy hotel <br />
          Are you sure you would like to permanently delete
          <strong>{{ object.name }}</strong
          >?<br />
          Confirm you want to permanently destroy this hotel by entering its
          name below.
          <div style="height: 20px"></div>
          <v-text-field
            v-model="object.name"
            disabled
            outlined
            dense
            hide-details
          >
          </v-text-field>
          <div style="height: 20px"></div>
          <v-text-field v-model="deleteName" outlined dense hide-details>
          </v-text-field>
          <div style="height: 20px"></div>
          <div style="display: flex; flex: 1">
            <v-btn
              dense
              style="
                flex: 1;
                max-width: 50%;
                margin-right: 15px;
                margin-bottom: 15px;
              "
              small
              color="primary"
              @click="closeDeleteModal"
            >
              Cancel
            </v-btn>
            <v-btn
              outlined
              dense
              :disabled="deleteName !== object.name"
              color="red"
              small
              style="
                margin-left: 15px;
                margin-bottom: 15px;
                flex: 1;
                max-width: 50%;
              "
              @click="confirm"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import ObjectsEmpty from "@/components/ObjectsEmpty.vue";
import moment from "moment";
// import Chart from '@/components/Chart.vue'
import PopupTariff from "@/components/Popup/PopupTariff";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import PopupEmail from "@/components/Popup/PopupEmail.vue";
import { mapMutations, mapState } from "vuex";
import store from "@/store";
import titleMixin from "@/mixins/titleMixin";

export default {
  name: "Objects",
  title() {
    return `My property`;
  },
  mixins: [titleMixin],
  components: {
    ObjectsEmpty,
    PopupTariff,
    PopupDelete,
    PopupEmail,
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('objects/getList')
        .then((data) => {
            next();
        }).catch(() => {
        next();
    })
  },
  data() {
    return {
      isMobile:false,
      title: this.$t('objects["Мои объекты"]'),
      dialogQr: false,
      dialogQrImage: "",
      deleteName: "",
      dialogName: false,
      address: "",
      name: "",
      changedName: "",
      index: null,
      dialogAddress: false,
      qrLink:"",
      nameRules: [
        v => !!v || 'Field is required',
      ],
      deleteObj: null,
      deleteModal: false,
      object: {
        id: null,
        name: null,
      },
      showModalRoomAdd: false,
      ModalRoomSaveErr: "",
      ModalRoomSaveSuccess: "",
    };
  },
  computed: {
    ...mapState(["objects"]),
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  mounted() {
  },
  methods: {
    ...mapMutations("objects", ["setItem"]),
    dateObj(date) {
      return moment(date).fromNow();
    },
    logoTest(name) {
      let arr = name.split(" ");
      if (arr.length === 1) {
        return name[0].toUpperCase();
      } else {
        return arr[0][0].toUpperCase() + arr[1][0].toUpperCase();
      }
    },
    saveName() {
      this.objects.list[this.index].name = this.name;
      let data = {
        id: this.objects.list[this.index].id,
        name: this.objects.list[this.index].name,
        address: this.objects.list[this.index].address,
        tariff_id: this.objects.list[this.index].tariff_id,
      };
      this.$store.dispatch("objects/edit", data);
      this.dialogName = false;
      this.name = null;
      this.index = null;
    },
    saveAddress() {
      this.objects.list[this.index].address = this.address;
      let data = {
        id: this.objects.list[this.index].id,
        name: this.objects.list[this.index].name,
        address: this.objects.list[this.index].address,
        tariff_id: this.objects.list[this.index].tariff_id,
      };
      this.$store.dispatch("objects/edit", data);
      this.dialogAddress = false;
      this.name = null;
      this.index = null;
    },
    async openRouteNew(name,id,lang){
      await this.$router.push({
        name: name,
        query: { id: id },
        params: { lang: lang },
      });
    },
    async openRoute(name, id, lang) {
    if (name === "Constructor") {
      await this.$router.push({ name: name, params: { id: id, lang: lang } });
    } else {
      await this.$router.push({
        name: name,
        query: { object: id },
        params: { lang: lang },
      });
    }
    },
    downloadURI(uri, name) {
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadQr() {
      const imgSrc = this.$store.state.isMobile?document.getElementById("qr_code").firstChild.toDataURL("image/png"):document.getElementById("qr_code").lastChild.src
      this.downloadURI(imgSrc,'guest_eco_'+this.dialogQrImage+".png");
    },
    openQr(item) {
      this.qrLink = 'https://guest.eco/'+item.id;
      this.dialogQr = true;
      this.dialogQrImage = item.id;
    },
      async toRooms(object) {
      await this.$router.push("/rooms");
      this.$store.commit("objects/setItem", object);
    },
      async handleOpenSettings(object) {
      this.setItem(object);
      await this.$router.push({
        name: "ObjectsSuccess",
        params: { lang: this.$route.params.lang, title: "property Settings" },
      });
    },
      async goToStickersCreate() {
      await this.$router.push({
        name: "StickersCreate",
        params: { lang: this.$route.params.lang },
      });
    },
      async goToEditObject(id) {
      await this.$router.push({
        name: "ObjectsEdit",
        params: { id: id, lang: this.$route.params.lang },
      });
    },
    changeName(name, index) {
      this.changedName = name;
      this.name = name;
      this.index = index;
      this.dialogName = true;
    },
    changeAddress(address, index) {
      this.changedName = name;
      this.address = address;
      this.index = index;
      this.dialogAddress = true;
    },
      async cloneObject(id) {
      await this.$router.push({
        name: "Clone property",
        params: { id: id, lang: this.$route.params.lang },
      });
    },
    deleteObject(id) {
      this.deleteName = "";
      this.deleteId = id;
      this.object = this.objects.list.find((object) => object.id === id);
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.deleteId = null;
    },
    confirm(confirm) {
      this.deleteModal = false;
      this.$store
        .dispatch("objects/deleteItem", this.object.id)
        .then((response) => {});
    },
    getPeriod(object) {
      this.$axios
        .get("/get-statistic", {
          params: {
            hotel_id: object.id,
            period: 1,
          },
        })
        .then((response) => {
          let now = response.data.now;
          let past = response.data.previous;

          if (now) {
            object.now = now.opening_institution_page;
          } else {
            object.now = 0;
          }

          if (past) {
            object.past = past.opening_institution_page;
          } else {
            object.past = 0;
          }
        })
        .catch((error) => {
          object.now = 0;
          object.past = 0;
        });
    },
    getStatistics(object) {
      this.$axios
        .get("/get-statistic-for-period", {
          params: {
            hotel_id: object.id,
            date_start: this.date_start,
            date_end: this.date_end,
          },
        })
        .then((response) => {
          // console.log(response.data.statictics);
          object.visitors = [];
          response.data.statictics = response.data.statictics.reverse();

          if (response.data.statictics.length === 1) {
            object.visitors.push(0);
          }

          response.data.statictics.forEach((item) => {
            object.visitors.push(+item.visitors);
          });
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    downloadPdf() {
      this.$refs.PopupEmail.open();
    },
  },
};
</script>

<style lang="scss">
.object {
  position: relative;
  margin-bottom: 30px;
  padding: 30px 30px 0;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
}

.object__status {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: $primary;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
}
.object__pwa {
  position: absolute;
  top: 0;
  right: 60px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: $primary;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
}

.object__status_expired {
  background: $error;
}

.object__name {
  font-weight: 600;
  font-size: 24px;
}

.object__actions {
  position: absolute;
  top: 30px;
  right: 30px;
}

.object__action {
  & + & {
    margin-left: 10px;
  }
}

.object__content {
  @include row;
}

.object__info {
  @include col(3);
  margin-bottom: 30px;

  canvas {
    width: 100% !important;
    height: 70px !important;
  }
}
.object__info__medium {
  @include col(4);
  margin-bottom: 30px;

  canvas {
    width: 100% !important;
    height: 70px !important;
  }
}
.object__info__high {
  @include col(5);
  margin-bottom: 30px;

  canvas {
    width: 100% !important;
    height: 70px !important;
  }
}

.object__label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #7f8385;
}

.object__value {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.31;
}

.object__subinfo {
  width: 100%;
}
.object__sticker-download {
  margin-right: 10px;
}

.object__value_links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
